<template>
  <div class="pad-home">
    <HeaderBar
      title="订单状态"
      :showLeft="true"
    ></HeaderBar>
    <div
      class="waitDriver"
      v-if="orderDetail"
    >
      <!-- background detail -->
      <div :class="bgClassObj">
        <!-- 顶部信息  -->
        <!-- 订单状态不为1或者订单状态为6并且订单司机已接单 -->
        <div v-if="status>1">
          <!-- order-step -->
          <div class="driverTips">{{statusTxt[status-1]}}</div>
          <!-- startoff time -->
          <div class="startoffTime">
            <i :class="[status<6?'startoff-icon':'startoff-icon-finish']"></i>
            <span v-if="time==1">立即出发</span>
            <span v-if="time==2">{{orderDetail.NewAppointmentTime}}</span>
          </div>
          <!-- driver info -->
          <div
            class="driverInfo"
            v-if="status<6&&status>1"
          >
            <div class="driver-image">
              <img
                :src="imgUrl"
                alt
              />
            </div>
            <div class="driver-des">
              <div class="driver-name">{{orderDetail.PartnerName}}</div>
              <div class="driver-stars">
                <div class>{{starNumber.toFixed(1)}}</div>
                <div class="star-list">
                  <i
                    class="icon-star"
                    v-for="(item,index) in starNumber"
                    :key="index"
                  ></i>
                </div>
              </div>
            </div>
            <a
              class="driver-tel"
              :href="'tel:'+orderDetail.Tel"
            ></a>
          </div>
          <!-- if status equals 5 the order is finished and the driver's tel hidden-->
          <div
            class="driverInfo"
            v-if="status>5"
          >
            <div class="driver-image">
              <img
                :src="imgUrl"
                alt
              />
            </div>
            <div class="driver-des-finish">
              <div class="driver-name-finish">{{orderDetail.PartnerName}}</div>
              <div class="driver-stars">
                <div class>{{starNumber.toFixed(1)}}</div>
                <div class="star-list">
                  <i
                    class="icon-star"
                    v-for="(item,index) in starNumber"
                    :key="index"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 订单状态为1或者订单状态为6而且司机未接单 -->
        <div v-if="status==1">
          <!-- order-step -->
          <div class="driverTips wait-margin">{{statusTxt[status-1]}}</div>
          <!-- startoff time -->
          <div class="startoffTime">
            <i :class="[status<6?'startoff-icon':'startoff-icon-finish']"></i>
            <span>{{orderDetail.NewAppointmentTime}}</span>
          </div>
        </div>
      </div>
      <!-- order info -->
      <div class="order-info">
        <div class="order-type">
          <div :class="['order-type-info',{'order-type-trip':type==1,'order-type-designated':type==2,'order-type-errand':type==3,'order-type-shopping':type==4}]">{{type==1?'专车':type==2?'代驾':type==3?'取送件':'代购'}}</div>
          <div :class="['order-type-info','order-intime',{'order-time-now':time==1,'order-time-appointment':time==2}]">{{time==1?'即时订单':'预约订单'}}</div>
        </div>
        <!-- <div class="order-title"
             v-if="type==3||type==4">
          帮我把一份文件送到我家来帮我把一份文件送到我家来
        </div>-->
        <div class="order-route">
          <div class="order-address order-startAddress">{{orderDetail.StratAddress}}</div>
          <div
            class="order-address order-transAddress"
            v-for="(item,index) in orderDetail.AfterAddress"
            :key="index"
          >{{item.city}}</div>
          <div class="order-address order-endAddress">{{orderDetail.EndAddress}}</div>
        </div>
        <div class="order-number">订单号码：{{orderDetail.OrderNumberID}}</div>
        <div
          class="remark"
          v-if="orderDetail.Rebak"
        >
          <div class="remark-content">
            <div>备注信息：{{orderDetail.Rebak}}</div>
            <div
              class="remark-imgs"
              v-if="orderDetail.UploadImg!=null"
            >
              <div
                v-for="(img,index) in orderDetail.UploadImg.split(',')"
                :key="index"
                class="remark-imgs-item"
                @click="showImagePreview(orderDetail.UploadImg.split(','),index)"
              >
                <!-- <img :src="img"
                alt="">-->
                <van-image
                  width="1.3rem"
                  height="1.3rem"
                  fit="cover"
                  :src="img"
                />
              </div>
            </div>
          </div>
          <div class="remark-imgs"></div>
        </div>
        <div class="order-price">
          <div>
            <div
              class="order-tip"
              v-if="time==2"
            >小费 ${{(orderDetail.Gratuity)}}</div>
            <div class="order-coupon">优惠券 -${{(orderDetail.Discount)}}</div>
          </div>

          <div class="order-cost">
            <span>最后</span>
            <span class="cost-dollar">${{((orderDetail.PayAmount-orderDetail.Discount))}}</span>
            <span>(¥{{Math.floor((orderDetail.PayAmount-orderDetail.Discount)*rateNum)}})</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import { Rate, ImagePreview, Image } from "vant";
import { getOrderDetailByOrderId } from "@/service/";
import { mapState, mapActions } from "vuex";

export default {
  name: "sharedroute",
  components: {
    HeaderBar,
    [Rate.name]: Rate,
    [Image.name]: Image,
  },
  data() {
    return {
      starNumber: 5,
      rateStars: 3,
      starsNum: 1,
      // msg: '',
      orderDetail: "",
      statusTxt: [
        "等待司机接单",
        "等待司机响应",
        "司机已知本单,正在赶来",
        "司机到达预约地，等待乘客上车",
        "正在前往目的地",
        "行程已结束",
        "行程已取消",
        "订单已过期",
        "订单被拒",
      ],
      timer: null,
      type: "1", //初始订单类型
      time: "1",
      orderId: getStore("orderId"),
      status: "1", //初始化订单状态
      // index: 0
    };
  },
  computed: {
    ...mapState({
      rateNum: ({ globalVal }) => globalVal.rateNum,
    }),
    orderId() {
      // 订单Id
      return this.$route.params.orderId;
    },
    bgClassObj() {
      return {
        "wait-bg": true,
        "bg-trip": this.type == 1 && this.status != 6,
        "bg-designated": this.type == 2 && this.status != 6,
        "bg-errand": this.type == 3 && this.status != 6,
        "bg-shopping": this.type == 4 && this.status != 6,
        "bg-finish": this.status > 5,
        white: this.status < 6,
        black: this.status > 6,
      };
    },
    imgUrl() {
      return require(`../../assets/logo.png`);
    },
    // images () {
    //   return this.orderDetail && this.orderDetail.UploadImg.split(',')
    // }
  },
  methods: {
    ...mapActions(["exchangeRateHandler"]),
    onChange(index) {
      this.index = index;
    },
    showImagePreview(images, position, timer) {
      const instance = ImagePreview({
        images,
        asyncClose: !!timer,
        closeOnPopstate: true,
        startPosition: typeof position === "number" ? position : 0,
      });
      if (timer) {
        setTimeout(() => {
          instance.close();
        }, timer);
      }
    },
    // // 创建连接
    // connectServer () {
    //   let $this = this;
    //   let conn = $.hubConnection(requestUrl.signalrUrl, { qs: "clientId=1232222" })
    //   $this.proxy = conn.createHubProxy("orderHub");
    //   $this.getMsg();
    //   conn.start().done(data => {
    //     $this.sendMsg();
    //     $this.getMsg();
    //     console.log(data)
    //   }).fail(err => {
    //     console.log(err)
    //   });

    // },
    // // 发送消息
    // sendMsg () {
    //   var $this = this;
    //   $this.proxy.invoke("Hell", $this.value).done((msg) => {
    //     console.log(msg)
    //   });
    // },
    // // 推送消息
    // getMsg () {
    //   var $this = this;
    //   $this.proxy.on("updateorder", (data) => {
    //     $this.msg = data;
    //   })
    // },
    // 获取订单信息
    async getOrderDetail(orderId) {
      if (this.status > 5) {
        clearInterval(this.timer);
        this.timer = null;
        // return
      }
      let res = await getOrderDetailByOrderId(orderId);
      if (res.code == 1) {
        if (res.data.AfterAddress) {
          res.data.AfterAddress = JSON.parse(res.data.AfterAddress);
        }
        this.orderDetail = res.data;
        if (this.status != res.data.State) {
          this.status = res.data.State;
          this.type = res.data.OrderType;
          this.time = res.data.IsAssign;
          if (this.status > 5) {
            clearInterval(this.timer);
            this.timer = null;
          }
          this.$router.replace(`/sharedroute/${this.orderId}`);
        }
      }
    },
    handleInterval() {
      clearInterval(this.timer);
      this.timer = null;
      if (this.state > 5) return;
      // console.log(this.state)
      // console.log(this)
      this.timer = setInterval(() => {
        this.getOrderDetail(this.orderId);
        if (this.state > 5) {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 10000);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.status > 5) {
        this.getOrderDetail(this.orderId);
      } else {
        this.getOrderDetail(this.orderId);
        // this.handleInterval()
      }

      // this.exchangeRateHandler()
    });
    if (!this.rateNum) {
      this.exchangeRateHandler();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.pad-home {
  padding-top: 94px;
}
.waitDriver {
  width: 690px;
  margin: 30px auto;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  .wait-bg {
    .size(690px, 360px);
    padding: 60px 30px 40px;
    box-sizing: border-box;
  }
  .bg {
    &-trip {
      background: url("../../assets/bg_trip.png") no-repeat;
      background-size: 690px 360px;
    }
    &-designated {
      background: url("../../assets/bg_designated.png") no-repeat;
      background-size: 690px 360px;
    }
    &-errand {
      background: url("../../assets/bg_errand.png") no-repeat;
      background-size: 690px 360px;
    }
    &-shopping {
      background: url("../../assets/bg_shopping.png") no-repeat;
      background-size: 690px 360px;
    }
    &-finish {
      background: url("../../assets/bg_finish.png") no-repeat;
      background-size: 690px 360px;
    }
  }
}
.wait-margin {
  margin: 40px 0 50px;
}
.driverTips {
  text-align: center;
  font-size: 36px;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  // color: rgba(255, 255, 255, 1);
  line-height: 50px;
}
.startoffTime {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 58px;
  text-align: center;
  font-size: 30px;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  // color: rgba(255, 255, 255, 1);
  line-height: 42px;

  .startoff-icon {
    display: inline-block;
    .size(26px, 26px);
    background: url("../../assets/white-clock@2x.png") 0 0 no-repeat;
    background-size: 26px 26px;
    margin-right: 18px;
  }
  .startoff-icon-finish {
    display: inline-block;
    .size(26px, 26px);
    background: url("../../assets/grey-clock@2x.png") 0 0 no-repeat;
    background-size: 26px 26px;
    margin-right: 18px;
  }
}

.driverInfo {
  display: flex;
  flex: 1;
  align-items: center;

  .driver {
    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      .size(80px, 80px);
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;
      .bg(#fcfcfc);
      img {
        width: 50px;
      }
    }
    &-des {
      margin-left: 20px;
      margin-right: auto;
      &-finish {
        display: flex;
      }
    }
    &-name {
      font-size: 30px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      line-height: 42px;
      &-finish {
        font-size: 30px;
        font-family: "PingFangSC-Medium";
        font-weight: 500;
        line-height: 42px;
        margin-right: 20px;
      }
    }
    &-stars {
      display: flex;
      flex: 1;
      height: 34px;
      margin-top: 4px;
      align-items: center;
      font-size: 24px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(245, 166, 35, 1);
      line-height: 34px;
    }
    &-tel {
      .size(80px, 80px);
      background: url("../../assets/tel@2x.png") 0 0 no-repeat;
      background-size: 80px 80px;
    }
  }
}
.star-list {
  display: flex;
  flex: 1;
  align-items: center;
  height: 34px;

  .icon-star {
    display: block;
    .size(26px, 24px);
    margin-left: 6px;
    background: url("../../assets/star-big@2x.png") 0 0 no-repeat;
    background-size: 26px 24px;
  }
}
.remark {
  display: flex;
  margin: 30px 0 20px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: rgba(248, 248, 248, 1);
  border-radius: 4px;
  font-size: 20px;
  font-family: "PingFangSC-Light";
  font-weight: 300;
  color: rgba(153, 153, 153, 1);
  line-height: 28px;

  &-content {
    width: 100%;
  }

  &-imgs {
    display: flex;
    flex: 1;
    width: 100%;
    // justify-content: space-around;
    margin: 15px auto;
    &-item {
      width: 100px;
      height: 100px;
      overflow: hidden;
      margin-right: 20px;
      img {
        width: 100%;
        // height:100px;
      }
    }
  }
}
.order-info {
  padding: 20px 30px;

  .order-type {
    display: flex;
    flex: 1;
    margin-bottom: 30px;
    align-items: center;
    justify-content: space-between;

    .order-type-info {
      .size(120px, 50px);

      border-radius: 4px;
      .text-center;
      font-size: 30px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;

      line-height: 50px;
    }
    &-trip {
      background: rgba(12, 170, 50, 0.1);
      color: rgba(12, 170, 50, 1);
    }
    &-designated {
      background: rgba(245, 166, 35, 0.1);
      color: rgba(245, 166, 35, 1);
    }
    &-errand {
      background: rgba(102, 155, 131, 0.1);
      color: rgba(102, 155, 131, 1);
    }
    &-shopping {
      background: rgba(205, 138, 80, 0.1);
      color: rgba(205, 138, 80, 1);
    }

    .order-intime {
      font-size: 24px;
    }
  }

  .order-title {
    margin: 20px 0 30px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 44px;
  }
}
.order-time {
  &-now {
    background: rgba(12, 170, 50, 0.1);
    color: rgba(12, 170, 50, 1);
  }
  &-appointment {
    background: rgba(245, 166, 35, 0.1);
    color: rgba(245, 166, 35, 1);
  }
}

.order-address {
  // height: 40px;
  padding-left: 48px;
  margin-bottom: 10px;
  font-size: 28px;
  font-family: "PingFangSC-Light";
  font-weight: 300;
  color: rgba(34, 34, 34, 1);
  line-height: 40px;
  // overflow: hidden;
  // text-overflow: ellipsis;
}
.order-startAddress {
  background: url("../../assets/start@2x.png") 0 6px no-repeat;
  background-size: 28px 28px;
}
.order-transAddress {
  background: url("../../assets/Oval Copy@2x.png") 0 6px no-repeat;
  background-size: 28px 28px;
}
.order-endAddress {
  background: url("../../assets/end@2x.png") 0 6px no-repeat;
  background-size: 28px 28px;
}
.order-number {
  height: 34px;
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 24px;
  font-family: "PingFangSC-Light";
  font-weight: 300;
  color: rgba(153, 153, 153, 1);
  line-height: 34px;
}

.order-price {
  display: flex;
  flex: 1;
  margin-bottom: 50px;
  align-items: center;
  justify-content: space-between;

  .order-coupon {
    font-size: 24px;
    font-family: "PingFangSC-Light";
    font-weight: 300;
    color: rgba(153, 153, 153, 1);
    line-height: 34px;
  }
  .order-tip {
    font-size: 24px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 34px;
  }

  .order-cost {
    font-size: 24px;
    font-family: "PingFangSC-Light";
    font-weight: 300;
    color: rgba(153, 153, 153, 1);

    .cost-dollar {
      .color(@brand-green);
      .fontSize(40px);
      font-family: "PingFangSC-Semibold";
      font-weight: 500;
    }
  }
}

.order {
  &-set {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }
  &-btn {
    .size(300px, 80px);
    margin-bottom: 30px;
    border-radius: 4px;
    background: rgba(238, 238, 238, 1);
    text-align: center;
    font-size: 30px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 80px;
  }
}
</style>
